/* Workflow page CSS Only */
.workflow-page .board-body {
    overflow-y: auto !important;
    /* overflow-x: auto !important; */
}

.workflow-page.container-fluid {
    /* max-width: 1300px; */
    /* padding-top: 97px; */
    padding-bottom: 60px;
    padding-left: 0px;
    margin-left: 15px;
}

.workflow-page p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.workflow-page .workflow-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px 0;
    margin-top: 50px;
    flex-wrap: wrap;
    /* Allows wrapping on smaller screens */
    margin-bottom: 30px;
}

.workflow-page .workflow-body .left-section {
    display: flex;
    align-items: center;
    gap: 5px;
}

.workflow-page .workflow-body .right-section {
    display: flex;
    align-items: center;
    gap: 5px;
}

.workflow-page .workflow-body .add-workflow {
    display: flex;
    justify-content: first baseline;
    align-items: center;
    cursor: pointer;
    color: #808080;
    font-weight: 500;
}

.workflow-page .workflow-body .clearable3 {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.33px solid #808080;
    border-radius: 5px;
    width: 286px;
    padding-left: 10px;
}

.workflow-page .workflow-body .clearable3 .clearable__clear {
    display: block;
    color: #808080;
    top: 3px;
}

.workflow-page .workflow-body .clearable3 img {
    width: 18px;
    height: 18px;
    filter: opacity(0.5);
}

.workflow-page .workflow-body .clearable3 input {
    border-bottom: unset !important;
    padding-left: 5px !important;
}

.workflow-page .workflow-body .clearable3 input::placeholder {
    font-size: 16px;
}


/* Workflow rows */
.workflows {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    position: relative;
}

/* .html2-canvas .workflows {
    width: max-content;
} */

.workflows .actions {
    position: absolute;
    bottom: 10px;
    left: 10px;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.workflows .actions a {
    color: #808080;
    padding: 5px;
    display: flex;
}

.workflows .actions a:hover {
    text-decoration: none;
}

.workflows .actions a img {
    opacity: 0.8;
}

.workflows .workflow {
    display: flex;
    /* Aligns items in a row */
    gap: 10px;
    /* Adds space between items */
    /* padding: 10px; */
    min-width: max-content;
    /* Ensures it doesn't shrink */
    border: 2px solid #B5B4B3;
    border-radius: 10px;
    padding-bottom: 40px;
    margin-bottom: 30px;
    position: relative;
}

.workflows .workflow .item {
    flex: 0 0 auto;
    /* Prevents items from shrinking */
    width: 300px;
    /* Set a fixed width or adjust as needed */
    /* border: 1px solid #ddd; */
    padding: 15px;
    /* background: white; */
    /* border-radius: 8px; */
    position: relative;
}

.workflow-page .workflows .workflow .new-step {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #808080;
    font-weight: 500;
    width: 150px;
}

.html2-canvas .new-step {
    display: none !important;
}

.workflow-page .workflows .workflow .item:not(.new-step)::after {
    content: "";
    position: absolute;
    right: -7px;
    /* Aligns to the right */
    top: 10%;
    /* Adjust to shorten from the top */
    height: 80%;
    /* Shorter height than the item */
    width: 1px;
    background-color: #B5B4B3;
    /* Same as border color */
}

.workflow-page .workflows .workflow .item.wf::after {
    width: 3px;
}

.workflow-page .workflows .item .title {
    font-size: 16px;
    font-weight: 500;
    color: #808080;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.workflow-page .workflows .item.wf .name {
    border-bottom: 5px solid #48DFB6;
    margin-bottom: 10px;
}

.workflow-page .workflows .item .name input,
.workflow-page .workflows .item .name input:focus,
.workflow-page .workflows .item .name input:active {
    outline: unset !important;
    border: unset !important;
    border-color: unset !important;
    border-radius: unset !important;
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
    -webkit-transition: unset !important;
    -o-transition: unset !important;
    -webkit-transition: unset !important;
    transition: unset !important;
    background-color: unset !important;
}

.workflow-page .workflows .item .name input,
.workflow-page .workflows .item .name input::placeholder {
    font-size: 22px;
    font-weight: 600;
    padding-left: 0;
}

.workflow-page .workflows .item .name input {
    color: #000000 !important;
}

.workflow-page .workflows .item .name input::placeholder {
    color: #555555 !important;
    opacity: 0.3;
}

.workflow-page .workflows .item.st .name {
    border-bottom: 5px solid #96C2EE;
    margin-bottom: 10px;
}

.workflow-page .workflows .item .owner-type {
    margin-top: 10px;
}

.workflow-page .workflows .item .owner {
    margin-bottom: 10px;
}

.workflow-page .workflows .item .notes textarea {
    color: #555555;
    border: none;
    border-radius: unset;
    height: 60px;
    width: 100%;
    resize: none;
    padding: 5px 5px 5px 0px;
    overflow: hidden;
    /* border-bottom: 1px solid #808080; */
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
    font-size: 16px;
}

.workflow-page .tracking-part h2 {
    margin-top: 10px;
}

.workflow-page .tracking-part p img {
    width: 16px;
}





.wfselect__value-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
    align-items: baseline !important;
}

.wfselect__indicator-separator,
.multi-select-container .wfselect__clear-indicator {
    display: none !important;
}

.multi-select-container .wfselect__multi-value__remove svg {
    width: 20px;
    height: 20px;
    color: #CCCCCC !important;
}

.multi-select-container .wfselect__multi-value__remove svg:hover {
    color: #999999 !important;
}

.wfselect__indicators {
    align-items: baseline !important;
}

.wfselect__indicator {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.wfselect__control {
    border-color: unset !important;
    border-style: unset !important;
    border-width: unset !important;
    box-shadow: unset !important;
}

.wfselect__input-container {
    width: 100% !important;
    display: block !important;
}

.multi-select-container .wfselect__value-container--has-value .wfselect__input-container[data-value=""]::before {
    content: "Owner";
    position: absolute;
    bottom: 8px;
    left: 3px;
    font-size: 16px;
    color: #999999;
    pointer-events: none;
}

.wfselect__multi-value {
    width: 100% !important;
    justify-content: space-between !important;
    background-color: unset !important;
}

.wfselect__multi-value__label {
    padding-left: 0 !important;
}

.wfselect__multi-value__label div {
    font-size: 16px !important;
}

.wfselect__multi-value__label div .custom-badge.blue {
    margin-bottom: auto !important;
}

.wfselect__menu-list {
    padding: 0 !important;
}

.wfselect__option {
    padding: 5px !important;
    font-size: 16px !important;
    background-color: #091e420a !important;
}

.wfselect__multi-value__remove {
    background-color: unset !important;
    color: unset !important;
}

/* Disabled styles */
.wfselect__option--is-focused,
.wfselect__option--is-selected {
    background-color: #d9dbe4 !important;
    color: #000 !important;
}

.wfselect--is-disabled .wfselect__control{
    background-color: unset !important;
}

.wfselect--is-disabled .wfselect__dropdown-indicator {
    display: none !important;
}

.workflow-page .workflows .notes textarea:disabled {
    background-color: unset !important;
}