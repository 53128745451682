/* Override fixes */
.app-board {
    padding-left: 70px;
}

.html2-canvas .app-board {
    padding-left: 0px;
}

.dropdown.total-fte .btn-mytpt {
    min-width: 105px;
}

/* .card-link, .card-unlink, .card-link-dl-focus {
    right: 0px !important;
    top: -10px !important;
} */
/* End */


.sidebar {
    position: absolute;
    top: 50px;
    left: 8px;
    width: 60px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    /* border-right: 1px solid #eee; */
}

.html2-canvas .sidebar {
    display: none;
}

.sidebar .icon {
    position: relative;
    width: 40px;
    height: 40px;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sidebar .icon:hover,
.sidebar .icon.active {
    background-color: #e0e0e0;
}

.sidebar .icon img {
    width: 24px;
    height: 24px;
}

.sidebar .separate-after::after {
    position: absolute;
    top: 50px;
    content: " ";
    width: 70%;
    height: 1px;
    background-color: #e0e0e0;
}

.sidebar .padlock {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 10px !important;
    height: auto !important;
}

.tippy-box[data-theme~="custom"] {
    background-color: black;
    /* Black background */
    color: white;
    /* White text */
    border-radius: 4px;
    /* Optional: Rounded corners */
    padding: 5px 10px;
    /* Optional: Add some padding */
    font-size: 14px;
    /* Optional: Font size */
}

.tippy-box[data-theme~="custom"] .tippy-arrow {
    color: black !important;
    /* Set the arrow color to black */
    fill: black !important;
    /* Ensure the arrow's SVG fill is black */
}

.tippy-box[data-theme~="custom"] .tippy-content {
    background-color: black;
    color: #fff;
}